<template>
  <main class="main-container">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
    <div class="header">
      <h1>Executive Summary</h1>
      <div class="account">
        <ETradeDropdown 
          :selected_account_id="selected_account_id"
          :account_ids="account_ids"
          @update:selected_account_id="handleAccountIdUpdate"
        />
      </div>
    </div>
    <h2>Active Strategies</h2>
    <div class="cards-container" v-if="filteredActives.length > 0">
      <ActiveStrat v-for="active in filteredActives" @update="refresh"
      :strat="active" />
    </div>
    <div v-else>None</div>
    <h2>Inactive Strategies</h2>
    <div class="cards-container" v-if="filteredInactives.length > 0">
      <InactiveStrat v-for="inactive in filteredInactives" @update="refresh"
        :strat="inactive" />
    </div>
    <div v-else>None</div>
  </main>
</template>

<script>
import ActiveStrat from '@/components/Summary/ActiveStrat.vue';
import InactiveStrat from '@/components/Summary/InactiveStrat.vue';
import ETradeDropdown from '../common/ETradeDropdown.vue';

export default {
  name: 'SummaryPage',
  components: {ActiveStrat, InactiveStrat, ETradeDropdown},

  data() {
    return {
      actives: [],
      inactives: [],
      account_ids: [
        { value: '9365', text: 'x9365' },
        { value: '9126', text: 'x9126' },
        { value: '9066', text: 'x9066' },
        { value: '9776', text: 'x9776' },
        { value: '9206', text: 'x9206' },
      ],
      selected_account_id: '9206',
    }
  },

  created() {
    if (!this.$store.state.username) {
      this.$router.push({ name: 'Login' });
    }
    this.refresh();
  },

  computed: {
    filteredActives() {
      return this.actives.filter(active => active.account_id === this.selected_account_id);
    },
    filteredInactives() {
      return this.inactives.filter(inactive => inactive.account_id === this.selected_account_id);
    }
  },


  methods: {
    async refresh() {
      try {
        this.getStrats();
      } catch (error) {
        console.error('Error in refresh:', error);
      }
    },
    
    getStrats() {
      fetch('/api/strats', {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin'
      })
      .then(res => res.json())
      .then(res => {
        this.actives = res.actives;
        this.inactives = res.inactives;
      })
      .catch(error => {
        console.error('API request error:', error);
        throw error;
      });
    },

    handleAccountIdUpdate(newAccountId) {
      this.selected_account_id = newAccountId;
    },

  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cards-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.main-container {
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Montserrat', Arial, sans-serif;
}

</style>
