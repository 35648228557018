<template>
    <div class="etrade-container">
      <h3 class="etrade-label">E-Trade Account</h3>
      <select class="custom-select" v-model="selected">
        <option disabled value="">Select Account</option>
        <option v-for="account in account_ids" :key="account.value" :value="account.value">
          {{ account.text }}
        </option>
      </select>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ETradeDropdown',
    props: {
      selected_account_id: {
        type: String,
        required: true
      },
      account_ids: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        selected: this.selected_account_id
      };
    },
    watch: {
      selected(newVal) {
        this.$emit('update:selected_account_id', newVal);
      }
    }
  };
  </script>
  
<style>
.etrade-container {
  font-family: 'Montserrat', sans-serif; /* Use the font that matches your design */
  width: 275px; /* Adjust the width as needed */
}

.etrade-label {
  display: block;
  margin-bottom: 8px;

}

.custom-select {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; /* Removes default styling on iOS */
  -moz-appearance: none;    /* Removes default styling on Firefox */
  appearance: none;         /* Removes default styling */
  font-family: 'Montserrat', sans-serif;
}

/* Add custom arrow indicator */
.custom-select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px;
}

/* Remove focus outline for a specific class - remember this could affect accessibility */
.custom-select:focus {
  outline: none;
}
</style>
  